<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
        v-model="datalist"
      >


        <!-- Columns -->
        <template #cell(id)="data">
            #{{ data.item.id }}
        </template>

        <!-- Column: Type -->
        <template #cell(${classVar}_type)="data">
          {{getCodeLabel('produceordercomplete_type', data.item.produceordercomplete_type)}}
        </template>

        <template #cell(add_time)="data">
          {{toDate(data.item.add_time)}}
        </template>

        <template #cell(modify_time)="data">
          {{toDate(data.item.modify_time)}}
        </template>

        <template #cell(product_unit)="data">
          {{getCodeLabel('product_unit', data.item.product_unit)}}
        </template>


        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <template #cell(true_qty)="data">
          <b-form-input
              id="trueqty"
              size="sm"
              v-model="data.item.true_qty"
              @change="checkQty(data.item)"
              type="number"
              style="width: 80px"
          />
        </template>



        <template #cell(boxQty)="data">
          {{data.item.ext['boxQty']}}
        </template>

      </b-table>
<!--      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          &lt;!&ndash; Pagination &ndash;&gt;
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

          <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>-->
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import producecompleteUseList from './producecompleteUseList'
import produceordercompleteStore from './produceordercompleteStore'
import productStore from '../product/productStore'
import ProductSelect from '@/views/apps/product/product-purchase/ProductSelect'
import {useToast} from 'vue-toastification/composition'
import ProduceInboundForm from '@/views/apps/produceorder/ProduceInboundForm'
export default {
  components: {
    BCard,
    ProduceInboundForm,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ProductSelect,
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('produceordercomplete/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },

  },
  data() {
    return {
      datalist:[],
    }
  },
  props: {
    produce_id: {
      type: Number,
      default: 0
    },
    produce_no: {
      type: String,
      default: ""
    },
    readonly:{
      type: Number,
      default: 0
    },
    warehouse_id:{
      type: Number,
      default:0,
    },
  },

  setup(props) {
    // Register module
    const toast = useToast()
    const warehouseId=props.warehouse_id
    if (!store.hasModule('produceordercomplete')) store.registerModule('produceordercomplete', produceordercompleteStore)
    if (!store.hasModule('product')) store.registerModule('product', productStore)


    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('produceordercomplete')) store.unregisterModule('produceordercomplete')
      if (store.hasModule('product')) store.unregisterModule('product')
    })



    const save = function (item) {
      store.dispatch('produceordercomplete/updateOrderItem', {
        itemid: item.item_id,
        //含税成本
        productcost: parseFloat(item.cost_price),
      })
              .then(res => {
                if (res.data.code==0){
                  toast.success('数据已保存!')
                  refetchData()
                }else {
                  toast.error(res.data.data)
                }

              })
    }


    const checkQty = function (item) {
      const cRegExp = /^[0-9]\d*$/
      if (!cRegExp.test(item.true_qty)) {
        toast.error('生产数量必须是正整数!')
        item.true_qty = 0
      }
    }


    const {
      searchList,
      tableColumns,
     /* limit,
      start,
      listTotals,
      dataMeta,*/

      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = producecompleteUseList(
            {produce_id:props.produce_id ,
             produce_no:props.produce_no
            }
    )

    return {
      searchList,
      save,
      tableColumns,
    /*  limit,
      start,
      listTotals,
      dataMeta,*/
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      warehouseId,
      // UI

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
      checkQty
    }
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
