<template>
  <div>
    <ValidationObserver ref="observer">
    <b-table-simple hover small caption-top responsive  >

      <b-thead v-show="items.length > 0">

        <b-tr>
          <b-th>序号</b-th>
          <b-th>生产日期</b-th>
          <b-th>有效日期</b-th>
          <b-th>批次</b-th>
          <b-th>仓位</b-th>
          <b-th>是否良品</b-th>
          <b-th>数量</b-th>
          <b-th>操作</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>

        <b-tr v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
        >

            <b-td>
              <span>{{ index + 1 }}</span>
            </b-td>
            <b-td>
              <ValidationProvider rules="required" name="生产日期" #default="{ errors }" :vid="`production_date_${index}`">
                <div v-if="saveShow==true">
                  <flat-pickr
                    id="production_date"
                    v-model="item.production_date"
                    @on-change="dateChange(index)"
                    class="form-control"
                />

                <small class="text-danger">{{ errors[0] }}</small>
                </div>
                <div v-if="saveShow===false">
                  <b-form-input
                      id="production_date"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                      v-model="item.production_date"
                      readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </b-td>
            <b-td>
              <ValidationProvider rules="required" name="有效日期" #default="{ errors }" :vid="`expiry_date_${index}`">
                <b-form-input
                    id="expiry_date"
                    v-model="item.expiry_date"
                    readonly
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

            </b-td>
          <b-td>
            <ValidationProvider rules="required" name="批次" #default="{ errors }" :vid="`batch_no_${index}`">
              <div v-if="saveShow===true">
              <b-form-input
                  id="batch_no"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                  v-model="item.batch_no"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </div>
              <div v-if="saveShow===false">
                <b-form-input
                    id="batch_no"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                    v-model="item.batch_no"
                    readonly
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </ValidationProvider>
          </b-td>
            <b-td>
              <ValidationProvider rules="required" name="仓位" #default="{ errors }" :vid="`location_${index}`">
                <div v-if="saveShow==true">
                <b-form-input
                    id="location"
                    :value="getCodeLabel('warehouse_location', item.location_fullname)"
                    @click="showModal(index)"
                    readonly
                    placeholder="点击搜索仓位"
                    :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                </div>
                <div v-if="saveShow==false">
                  <b-form-input
                      id="location"
                      :value="getCodeLabel('warehouse_location', item.location_fullname)"
                      readonly
                      placeholder="点击搜索仓位"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </b-td>
            <b-td>
              <div v-if="saveShow==true">
              <b-form-checkbox
                  name="check-button"
                  switch
                  value="0"
                  unchecked-value="1"
                  :id="condition.id+'status'+index"
                  v-model="item.status"
              ></b-form-checkbox>
              </div>
              <div v-if="saveShow==false">
                <b-form-input
                    :value="getCodeLabel('stock_status', item.status)"
                    readonly
                />
              </div>
            </b-td>
            <b-td>
              <ValidationProvider rules="required|nonnegativeInteger" name="数量" #default="{ errors }" :vid="`qty_${index}`">
                <div v-if="saveShow==true">
                <b-form-input
                    id="qty"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                    v-model="item.qty"
                    @change="checkData(item,index)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                </div>
                <div v-if="saveShow==false">
                  <b-form-input
                      id="qty"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                      v-model="item.qty"
                      readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </b-td>
            <b-td>
              <b-button
                  variant="outline-danger"
                  @click="removeItem(index)"
                  v-if="saveShow"
              >
                <feather-icon
                    icon="XIcon"
                    class="mr-25"
                />
                <span>删除</span>
              </b-button>
            </b-td>

        </b-tr>
      </b-tbody>
      <b-tfoot>

      </b-tfoot>
    </b-table-simple>
    </ValidationObserver>
    <b-col cols="12"
           class="mt-50"
    >
      <b-button
          variant="primary"
          class="mr-1"
          @click="repeateAgain"
          v-if="saveShow"
      >
        <feather-icon
            icon="PlusIcon"
            class="mr-25"
        />
        <span>增加一行</span>
      </b-button>
      <b-button
          id = "produceInboundFrom"
          variant="primary"
          @click="save"
          v-show="items.length > 0"
            v-if="saveShow"
      >
        <feather-icon
            icon="SaveIcon"
            class="mr-25"
        />
        <span>保存</span>
      </b-button>
    </b-col>
    <b-modal
        id="modal-select-location"
        ok-only
        ok-title="确认"
        @ok="onSelectLocation"
        cancel-title="取消"
        centered
        size="lg"
        title="选择我方仓库"
        ref="locationModal"
    >
      <warehouse-location-list
          ref="locationSelect" :warehouseId="warehouseId"
      >
      </warehouse-location-list>
    </b-modal>

  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import {onMounted, onUnmounted, reactive, ref} from '@vue/composition-api'
import warehouselocationStore from '@/views/apps/warehouselocation/warehouselocationStore'
import {getCode, getCodeOptions, getCodeLabel, compareTodayDate, second} from '@core/utils/filter'
import { addDate } from '@core/utils/utils'
import WarehouseLocationList from '@/views/apps/warehouselocation/modal/WarehouseLocationList'
export default {
  components: {
    WarehouseLocationList,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    flatPickr,
  },
  data() {
    return {
      items: [{ status: 0 }],
      selectedIndex: {},
      status: {},
      saveShow:true,
    }
  },
  props: {
    condition: {
      type: Object,
      default: null,
    },
    warehouseId: {
      type: Number,
      default: 0,
    },
  },


  methods: {
    dateChange(index){
      let item = this.items[index]
      if (!compareTodayDate(item.production_date)){
        this.items[index]["batch_no"]=null
        this.items[index]["expiry_date"]=null
        this.items[index]["production_date"]=null
      }
      if (item.production_date!=null){
        let array = (item.production_date).split("-")
        let no =""
        for (let i = 0; i < array.length; i++) {
          no+= array[i]
        }
        this.items[index]['batch_no'] = no
        let product = this.condition.ext.product
        let unit = getCodeLabel('product_unit_period',product.guarantee_period_unit,'remark')
        item.expiry_date = addDate(item.production_date,product.guarantee_period,unit)
        this.items.splice(index, 1,item)
      }

    },
    changeSelect(index, key, event) {
      this.items[index][key] = event == null ? undefined : event.value
    },
    repeateAgain() {
      this.items.push({ status: 0 })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    onSelectLocation() {
      let item = this.items[this.selectedIndex]

      let res = this.$refs.locationSelect.selected[0]
      if (res) {
        item.location_fullname = res.location_fullname
        item.location_id = res.id
        this.items.splice(this.selectedIndex, 1, item)
      }
    },
    showModal(index) {
      this.$refs['locationModal'].show()
      this.selectedIndex = index
    }

  },
  setup(props) {
    // Register module
    if (!store.hasModule('warehouselocation')) store.registerModule('warehouselocation', warehouselocationStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehouselocation')) store.unregisterModule('warehouselocation')
    })


    const toast = useToast()
    let condition = props.condition
    const checkData = function (item, index) {
      let qty = item.qty
      let maxQty = condition.qty
      let trueQty = parseInt(condition.trueqty)
      //可输入的数量限制
      let qtyLimit = maxQty - this.items.filter((item, i) => i !== index)
          .reduce(
              (acc, cur) => acc + parseInt(cur.qty)
              , trueQty
          )
    }

    const save = async function () {
      let item = props.condition
      if (this.items.length !== 0) {
        let items = this.items
        let success = await this.$refs.observer.validate()
        if (success) {
          this.saveShow = false
          let resultProduce = "resultProduce"
          let batchno = ''
          let productiondate = ''
          let expirydate = ''
          let batchqty = ''
          let stockstatus = ''
          let locationid = ''
          items.forEach(item => {
            batchno += item['batch_no'] + ','
            productiondate += item['production_date'] + ','
            expirydate += item['expiry_date'] + ','
            batchqty += item['qty'] + ','
            stockstatus += item['status'] + ','
            locationid += item['location_id'] + ','
          })
          batchno = batchno.substring(0, batchno.length - 1)
          productiondate = productiondate.substring(0, productiondate.length - 1)
          expirydate = expirydate.substring(0, expirydate.length - 1)
          batchqty = batchqty.substring(0, batchqty.length - 1)
          stockstatus = stockstatus.substring(0, stockstatus.length - 1)
          locationid = locationid.substring(0, locationid.length - 1)
          localStorage.setItem("stock_item",batchno)
          localStorage.setItem("stock_item1",productiondate)
          localStorage.setItem("stock_item2",batchqty)
          localStorage.setItem("stock_item3",stockstatus)
          localStorage.setItem("stock_item4",locationid)
          localStorage.setItem("stock_item5",expirydate)
          localStorage.setItem("resultProduce",resultProduce)
          localStorage.setItem("itemId",props.condition.item_id)
          toast.success("数据保存成功")
        } else {
          toast.error('请填写缺失字段')
        }
      } else {
        toast.error('请先进行入库数据填写')
      }
    }


    return {
      checkData,
      // getLocation,
      getCodeOptions,
      getCodeLabel,
      save,
      addDate,
      compareTodayDate,
    }
  },

}
</script>

<style lang="scss">
.repeater-form {
  display: flex;
  overflow: hidden;
  transition: .35s height;
  flex-direction: row
}
</style>
