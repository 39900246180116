<template>
  <b-card
    class="produceorder-edit-wrapper"
  >
   <!-- <validation-observer ref="simpleRules">-->
    <!-- form -->
    <b-form id="produceorderForm" class="edit-form mt-2">
      <!--成品明细-->
      <b-col md="12">
        <div class="inner-card-title">生产清单</div>
      </b-col>

      <b-col>
        <produce-complete-list ref="itemListComplete"  :readonly="readonly"  :produce_id="produceorder.produce_id" :produce_no="produceorder.produce_no" :warehouse_id="produceorder.warehouse_id"   v-if="produceorder.produce_id > 0" v-on:table="fromChildrenOnNum()">
        </produce-complete-list>
      </b-col>

      <!--原料明细-->
      <b-col md="12">
        <div class="inner-card-title">原料清单</div>
      </b-col>
      <produce-source-list  ref="itemList"  :readonly="readonly" :produce_id="produceorder.produce_id" :produce_no="produceorder.produce_no" :warehouse_id="produceorder.warehouse_id" v-if="produceorder.produce_id > 0"  >
      </produce-source-list>

      <!--保存按钮-->
      <b-col
              cols="12"
              class="mt-50"
      >
        <b-button
                variant="outline-secondary"
                class="mr-1"
                @click="onlySave"
                v-if="clickThen"
        >
          保存
        </b-button>
        <b-button
                variant="outline-secondary"
                class="mr-1"
                @click="cancel"
        >
          取消
        </b-button>
        <b-button
            variant="primary"
            @click="save"
            v-if="clickThen"
        >
          生产完成
        </b-button>
      </b-col>
    </b-form>

    <!--/ form -->
   <!-- </validation-observer>-->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onMounted, onUnmounted, reactive, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import produceorderStore from './produceorderStore'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,second } from '@core/utils/filter'
import ProduceSourceList from '@/views/apps/produceordersource/ProduceSourceList'
import ProduceCompleteList from '@/views/apps/produceordercomplete/ProduceCompleteList'
import operationlimitStore from "@/views/apps/operationlimit/operationlimitStore";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    UserSelect,
    WareHouseSelect,
    ProduceSourceList,
    ProduceCompleteList,
  },
  data() {
    return {
      id: 0,
      produceorder: {},
      isshowitemlist:true,
      readonly:0,
      warehouse_id:'',
      produce_id:'',
      produce_no:'',
      itemListShowFlag: true,
      istrue:false,
      clickThen : true,

    }
  },
  methods:{
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('produceorder')) store.registerModule('produceorder', produceorderStore)
    if (!store.hasModule('operationlimit')) store.registerModule('operationlimit', operationlimitStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('produceorder')) store.unregisterModule('produceorder')
      if (store.hasModule('operationlimit')) store.unregisterModule('operationlimit')
    })


    const edit = function() {
      this.itemListShowFlag = true
      store.dispatch('produceorder/edit', {id: this.id}).then(res => {
        this.produceorder = res.data.data
        this.produceorder.start_date = toDate(this.produceorder.start_date)
        this.produceorder.purchase_last_date = toDate(this.produceorder.purchase_last_date)
      })
    }

    const view = function() {
      store.dispatch('produceorder/view', {id: this.id}).then(res => {
        this.produceorder = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }




    const save = async function() {
      this.clickThen = false
      let produceOrderSource = this.$refs.itemList.datalist
      let length = produceOrderSource.length
      let myItemComplete = this.$refs.itemListComplete.datalist
      let lengthComplete =  myItemComplete.length
      if(length<=0||length==undefined){
        toast.error("最少选择一个原料")
        return false;
      }

      for(let i = 0 ;i<length;i++){
        let qty = produceOrderSource[i].true_qty;

        if(qty<=0||qty==undefined){
          toast.error("原料清单实际使用数量填写错误！")
          return false;
        }
      }
      for(let i = 0 ;i<lengthComplete;i++){
        console.log(myItemComplete[i])
        let qty = myItemComplete[i].true_qty;
        if(qty<=0||qty==undefined){
          toast.error("生产数量填写错误！")
          return false;
        }
      }
      store.dispatch('operationlimit/produceCheck', {
        type:3,
        checkOrDel:'check',
        produceordersourcelength: length,
        produceordersource: produceOrderSource,
      }).then(res=>{
        if (res.data.code==0){
          //出库
          store.dispatch('produceorder/savelast', {
            ...this.produceorder,
            produceordersourcelength: length,
            lengthComplete: lengthComplete,
            produceordersource: produceOrderSource,
            myitemComplete: myItemComplete,
          }).then(res => {
            if (res.data.code == 0) {
              toast.success('数据已保存!')
              this.$router.push({name: 'apps-produceorder-produce'});
            }
            else {
              toast.error(res.data.data)
              window.location.reload()
            }

            //删除
            store.dispatch('operationlimit/produceCheck', {
              type:3,
              produceordersourcelength: length,
              produceordersource: produceOrderSource,
              checkOrDel:'del',
            }).then(res=>{
              if (res.data.code==0){
                // refetchData()
                // _this.$refs['loadingModal'].hide()
              }else {
                // _this.$refs['loadingModal'].hide()
              }
            })
          })
        }else {
          toast.error(res.data.data)
        }

      })
    }

    const fromChildren = function (data,modal) {
      if (modal=='user'){
        this.produceorder.applier_id = returnData.user_id
        this.allotoutbound.applier_name = returnData.full_name
        this.$forceUpdate()
      }
      if (modal=="from"){
        //判斷是否已添加产品
        this.produceorder.warehouse_id = data.id
        this.produceorder.warehouse_name = data.warehouse_name
        this.$forceUpdate()
      }
      this.$bvModal.hide('modal-single')
    }

    const fromChildrenReceive = function (data,modal) {
      if (modal=='user'){
        this.produceorder.receive_id = returnData.user_id
        this.allotoutbound.receive_name = returnData.full_name
        this.$forceUpdate()
      }
      this.$bvModal.hide('modal-single')
    }

    const fromChildrenOnNum = function (val) {
      this.$bvModal.hide('modal-single')
    }

    const onlySave = async function() {
      this.clickThen = false
      let produceOrderSource = this.$refs.itemList.datalist
      let length = produceOrderSource.length
      let myItemComplete = this.$refs.itemListComplete.datalist
      let lengthComplete =  myItemComplete.length
      if(length<=0||length==undefined){
        toast.error("最少选择一个原料")
        return false;
      }

      for(let i = 0 ;i<length;i++){
        let qty = produceOrderSource[i].true_qty;

        if(qty<=0||qty==undefined){
          toast.error("原料清单实际使用数量填写错误！")
          return false;
        }
      }
      for(let i = 0 ;i<lengthComplete;i++){
        console.log(myItemComplete[i])
        let qty = myItemComplete[i].true_qty;
        if(qty<=0||qty==undefined){
          toast.error("生产数量填写错误！")
          return false;
        }
      }

      store.dispatch('produceorder/onlySave', {
        ...this.produceorder,
        produceordersourcelength:length,
        lengthComplete:lengthComplete,
        produceordersource:produceOrderSource,
        myitemComplete:myItemComplete,
      }).then(res => {
        if (res.data.code==0){
          toast.success('数据已保存!')
          this.$router.push({ name: 'apps-produceorder-produce'});
        }else {
          toast.error(res.data.data)
          window.location.reload()
        }
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      fromChildren,
      fromChildrenReceive,
      fromChildrenOnNum,
      onlySave,

    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.readonly = this.$route.params.readonly || null;
    if (this.$route.params.readonly===0||this.id===0){
      this.readonly=0
    }
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
