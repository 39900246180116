var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer"},[_c('b-table-simple',{attrs:{"hover":"","small":"","caption-top":"","responsive":""}},[_c('b-thead',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length > 0),expression:"items.length > 0"}]},[_c('b-tr',[_c('b-th',[_vm._v("序号")]),_c('b-th',[_vm._v("生产日期")]),_c('b-th',[_vm._v("有效日期")]),_c('b-th',[_vm._v("批次")]),_c('b-th',[_vm._v("仓位")]),_c('b-th',[_vm._v("是否良品")]),_c('b-th',[_vm._v("数量")]),_c('b-th',[_vm._v("操作")])],1)],1),_c('b-tbody',_vm._l((_vm.items),function(item,index){return _c('b-tr',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-td',[_c('span',[_vm._v(_vm._s(index + 1))])]),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required","name":"生产日期","vid":("production_date_" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.saveShow==true)?_c('div',[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"production_date"},on:{"on-change":function($event){return _vm.dateChange(index)}},model:{value:(item.production_date),callback:function ($$v) {_vm.$set(item, "production_date", $$v)},expression:"item.production_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1):_vm._e(),(_vm.saveShow===false)?_c('div',[_c('b-form-input',{attrs:{"id":"production_date","type":"text","state":errors.length > 0 ? false:null,"readonly":""},model:{value:(item.production_date),callback:function ($$v) {_vm.$set(item, "production_date", $$v)},expression:"item.production_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1):_vm._e()]}}],null,true)})],1),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required","name":"有效日期","vid":("expiry_date_" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"expiry_date","readonly":""},model:{value:(item.expiry_date),callback:function ($$v) {_vm.$set(item, "expiry_date", $$v)},expression:"item.expiry_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required","name":"批次","vid":("batch_no_" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.saveShow===true)?_c('div',[_c('b-form-input',{attrs:{"id":"batch_no","type":"text","state":errors.length > 0 ? false:null},model:{value:(item.batch_no),callback:function ($$v) {_vm.$set(item, "batch_no", $$v)},expression:"item.batch_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1):_vm._e(),(_vm.saveShow===false)?_c('div',[_c('b-form-input',{attrs:{"id":"batch_no","type":"text","state":errors.length > 0 ? false:null,"readonly":""},model:{value:(item.batch_no),callback:function ($$v) {_vm.$set(item, "batch_no", $$v)},expression:"item.batch_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1):_vm._e()]}}],null,true)})],1),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required","name":"仓位","vid":("location_" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.saveShow==true)?_c('div',[_c('b-form-input',{attrs:{"id":"location","value":_vm.getCodeLabel('warehouse_location', item.location_fullname),"readonly":"","placeholder":"点击搜索仓位","state":errors.length > 0 ? false:null},on:{"click":function($event){return _vm.showModal(index)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1):_vm._e(),(_vm.saveShow==false)?_c('div',[_c('b-form-input',{attrs:{"id":"location","value":_vm.getCodeLabel('warehouse_location', item.location_fullname),"readonly":"","placeholder":"点击搜索仓位","state":errors.length > 0 ? false:null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1):_vm._e()]}}],null,true)})],1),_c('b-td',[(_vm.saveShow==true)?_c('div',[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","value":"0","unchecked-value":"1","id":_vm.condition.id+'status'+index},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1):_vm._e(),(_vm.saveShow==false)?_c('div',[_c('b-form-input',{attrs:{"value":_vm.getCodeLabel('stock_status', item.status),"readonly":""}})],1):_vm._e()]),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required|nonnegativeInteger","name":"数量","vid":("qty_" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.saveShow==true)?_c('div',[_c('b-form-input',{attrs:{"id":"qty","type":"number","state":errors.length > 0 ? false:null,"placeholder":""},on:{"change":function($event){return _vm.checkData(item,index)}},model:{value:(item.qty),callback:function ($$v) {_vm.$set(item, "qty", $$v)},expression:"item.qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1):_vm._e(),(_vm.saveShow==false)?_c('div',[_c('b-form-input',{attrs:{"id":"qty","type":"number","state":errors.length > 0 ? false:null,"placeholder":"","readonly":""},model:{value:(item.qty),callback:function ($$v) {_vm.$set(item, "qty", $$v)},expression:"item.qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1):_vm._e()]}}],null,true)})],1),_c('b-td',[(_vm.saveShow)?_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("删除")])],1):_vm._e()],1)],1)}),1),_c('b-tfoot')],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[(_vm.saveShow)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("增加一行")])],1):_vm._e(),(_vm.saveShow)?_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length > 0),expression:"items.length > 0"}],attrs:{"id":"produceInboundFrom","variant":"primary"},on:{"click":_vm.save}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"SaveIcon"}}),_c('span',[_vm._v("保存")])],1):_vm._e()],1),_c('b-modal',{ref:"locationModal",attrs:{"id":"modal-select-location","ok-only":"","ok-title":"确认","cancel-title":"取消","centered":"","size":"lg","title":"选择我方仓库"},on:{"ok":_vm.onSelectLocation}},[_c('warehouse-location-list',{ref:"locationSelect",attrs:{"warehouseId":_vm.warehouseId}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }